import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

import './assets/css/common.css'

Vue.config.productionTip = false

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV == 'development') {
  Vue.prototype.$imgBaseUrl = 'https://www.路航通.com/api/system/static/' // 开发
  Vue.prototype.PRE_URL = 'https://www.路航通.com/' // 开发
} else {
  Vue.prototype.$imgBaseUrl = 'https://www.路航通.com/api/system/static/' // 生产
  Vue.prototype.PRE_URL = 'https://www.路航通.com/' // 生产
}
window.localStorage.setItem('PRE_URL', Vue.prototype.PRE_URL)

// Vue.prototype.$imgBaseUrl = 'http://39.98.59.164/api/system/static/'
// Vue.prototype.$imgBaseUrl = 'https://www.路航通.com/api/system/static/'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
