import Vue from 'vue'
import {
  Button,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  Dialog,
  Link,
  Carousel,
  CarouselItem,
  Table,
  TableColumn,
  MessageBox,
  Message,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  RadioButton,
  Tree,
  InputNumber,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Image,
  Upload,
  Select,
  TimeSelect,
  TimePicker,
  Loading,
  Option
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Link)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tree)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(InputNumber)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Select)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Option)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
