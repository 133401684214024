import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/Index'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home')
      },
      {
        path: '/news',
        component: () => import('@/views/News')
      },
      {
        path: '/news/details',
        component: () => import('@/views/Details')
      },
      {
        path: '/search',
        component: () => import('@/views/Search')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/Login')
  },
  {
    path: '/admin',
    redirect: '/admin/column',
    component: () => import('@/views/admin/Admin'),
    children: [
      // 系统管理
      {
        path: 'setting/menu',
        component: () => import('@/views/admin/setting/Menu')
      },
      {
        path: 'setting/user',
        component: () => import('@/views/admin/setting/User')
      },
      {
        path: 'setting/role',
        component: () => import('@/views/admin/setting/Role')
      },
      {
        path: 'column',
        component: () => import('@/views/admin/Column')
      },
      {
        path: 'slide',
        component: () => import('@/views/admin/Slide')
      },
      {
        path: 'articlelist',
        component: () => import('@/views/admin/ArticleList')
      },
      {
        path: 'articleadd',
        component: () => import('@/views/admin/ArticleAdd')
      },
      {
        path: 'edit',
        component: () => import('@/views/admin/EditPassword')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/NoFind')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
